import React from 'react';
import './Header.css';
import Logo from './../../assets/logo.jpeg';
import Background from './../../assets/background.jpg'

interface State {

}

interface Props {

}

var headerStyle = {
    backgroundImage: `url(${Background})`,
    backgroundSize: 'cover',
    
   // background:`linear-gradient(0deg, rgba(255, 0, 150, 0.3), rgba(255, 0, 150, 0.3)), url(${Background})`,

}

export default class Header extends React.Component<Props, State> {
    constructor(props){
        super(props);
        this.state = {}
    }

    render(){

        return(
            <div className="header" style={headerStyle}>
               <div className="header_overlay">
                <a className="noColor" href="https://wait.mycantura.de">
               
               <h2 className="claim">
               DAS ÖL DER INFLUENCER
               </h2>
               
               <p>Bestes Bio-ÖL mit 12% zum Testen</p>


               <p>
               Nur 19,90 € statt 39,90€
               </p>


               <div className="button_div">
                  <a href="https://wait.mycantura.de"> Hier Klicken und 20€ sparen </a>

                
               </div>

               
               </a>
               </div>
              
            </div>
        )
    }
}